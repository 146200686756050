<template>
    <div class="h-100 w-100 flex-in-middle dark" v-show="page_ready" :style="{backgroundImage:'url('+bg_image+')'}">
        <div class="login-wrap">
            <div class="left-part">
                <div class="title">{{ $t("TITLE.WELCOME") }}</div>
                <div class="line"></div>
                <div class="text">{{ $t("MESSAGE.WELCOME") }}
                </div>
            </div>
            <div class="right-part">
                <el-tabs v-model="tab" stretch v-el-tabs-large-header>
                    <el-tab-pane :label="$t('TITLE.SSO')" name="sso" class="position-relative tab-pane"
                                 :class="tab==='sso'?'active':''">
                        <div class="flex-in-middle" style="height: 220px;">
                            <el-button type="dark" size="large" round class="w-100" style="font-size: 20px;"
                                       @click="handleSSO">
                                {{  $t('TITLE.SSO-LOGIN') }}
                            </el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('TITLE.LOCAL')" name="local" class="position-relative tab-pane"
                                 :class="tab==='local'?'active':''">
                        <div class="">
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.id" clearable placeholder="Your ID"
                                          v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.password" show-password type="password" clearable
                                          placeholder="Password" v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 43px;">
                                <el-button type="dark" round class="w-100" @click="submitForm" style="height: 45px;">
                                    {{ $t("TITLE.SIGN-IN") }}
                                </el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="language">
                <div class="mr-2 locale" @click="setLocale">{{ english ? "English" : "中文" }}</div>
            </div>
            <div style="position:fixed;bottom: 0px;left: 0;right: 0;text-align: center">
                <page-footer></page-footer>
            </div>
        </div>
    </div>
</template>

<script>

import SignIn from "@/views/mixins/signin-mixin"
import "@/common/directives/el-tabs-large-header"
import "@/common/directives/el-round-input"
import PageFooter from "@/components/page-footer";

export default {
    components: {PageFooter},
    mixins: [SignIn],

}
</script>

<style lang="scss">
@import "~@/scss/global.scss";

.dark {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #fff;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

    .login-wrap {
        position: absolute;
        width: 800px;
        height: 402px;
        padding: 47px 65px 60px 27px;
        display: flex;

        background: white linear-gradient($--public-header-background, $--public-header-background) no-repeat;
        background-size: 50% 100%;
        border-radius: 50px;

        .left-part {
            color: white;
            margin-left: 22px;
            margin-top: 19px;
            width: 319px;
            height: 203px;

            .title {
                font-size: 1.5rem;
                font-weight: bold;
                height: 58px;
            }

            .line {
                width: 56px;
                height: 5px;
                background-color: white;
            }

            .text {
                margin-top: 21px;
            }

        }

        .right-part {
            color: black;
            margin-top: 13px;
            margin-left: 104px;
            width: 267px;
            height: 281px;

            .el-button--dark {
                background: $--public-header-background;
                color: white;
                transition: all ease-in 200ms;

                &:hover {
                    background: lighten($--public-header-background, 15%);
                }
            }

            .tab-pane {
                &.active {
                    animation:pane-move 1000ms;
                }
            }
            @keyframes pane-move
            {
                0% { opacity: 0; }
                100% {opacity: 1; }
            }
        }

        .language {
            position: absolute;
            bottom: 20px;
            right: 410px;
            color: white;
            cursor: pointer;
            transition: color ease-in 150ms;

            &:hover {
                color: $--public-highlight;
            }
        }
    }
}

</style>
